exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agency-about-js": () => import("./../../../src/pages/agency-about.js" /* webpackChunkName: "component---src-pages-agency-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-expertise-js": () => import("./../../../src/pages/expertise.js" /* webpackChunkName: "component---src-pages-expertise-js" */),
  "component---src-pages-hire-android-js": () => import("./../../../src/pages/hire/android.js" /* webpackChunkName: "component---src-pages-hire-android-js" */),
  "component---src-pages-hire-angular-js": () => import("./../../../src/pages/hire/angular.js" /* webpackChunkName: "component---src-pages-hire-angular-js" */),
  "component---src-pages-hire-automation-qa-js": () => import("./../../../src/pages/hire/automation-qa.js" /* webpackChunkName: "component---src-pages-hire-automation-qa-js" */),
  "component---src-pages-hire-aws-js": () => import("./../../../src/pages/hire/aws.js" /* webpackChunkName: "component---src-pages-hire-aws-js" */),
  "component---src-pages-hire-electron-js-js": () => import("./../../../src/pages/hire/electron-js.js" /* webpackChunkName: "component---src-pages-hire-electron-js-js" */),
  "component---src-pages-hire-flutter-js": () => import("./../../../src/pages/hire/flutter.js" /* webpackChunkName: "component---src-pages-hire-flutter-js" */),
  "component---src-pages-hire-frontend-developers-js": () => import("./../../../src/pages/hire/frontend-developers.js" /* webpackChunkName: "component---src-pages-hire-frontend-developers-js" */),
  "component---src-pages-hire-ios-js": () => import("./../../../src/pages/hire/ios.js" /* webpackChunkName: "component---src-pages-hire-ios-js" */),
  "component---src-pages-hire-lambda-js": () => import("./../../../src/pages/hire/lambda.js" /* webpackChunkName: "component---src-pages-hire-lambda-js" */),
  "component---src-pages-hire-laravel-js": () => import("./../../../src/pages/hire/laravel.js" /* webpackChunkName: "component---src-pages-hire-laravel-js" */),
  "component---src-pages-hire-magento-js": () => import("./../../../src/pages/hire/magento.js" /* webpackChunkName: "component---src-pages-hire-magento-js" */),
  "component---src-pages-hire-mean-js": () => import("./../../../src/pages/hire/mean.js" /* webpackChunkName: "component---src-pages-hire-mean-js" */),
  "component---src-pages-hire-mern-js": () => import("./../../../src/pages/hire/mern.js" /* webpackChunkName: "component---src-pages-hire-mern-js" */),
  "component---src-pages-hire-next-js-js": () => import("./../../../src/pages/hire/next-js.js" /* webpackChunkName: "component---src-pages-hire-next-js-js" */),
  "component---src-pages-hire-node-js-js": () => import("./../../../src/pages/hire/node-js.js" /* webpackChunkName: "component---src-pages-hire-node-js-js" */),
  "component---src-pages-hire-php-js": () => import("./../../../src/pages/hire/php.js" /* webpackChunkName: "component---src-pages-hire-php-js" */),
  "component---src-pages-hire-python-js": () => import("./../../../src/pages/hire/python.js" /* webpackChunkName: "component---src-pages-hire-python-js" */),
  "component---src-pages-hire-qa-js": () => import("./../../../src/pages/hire/qa.js" /* webpackChunkName: "component---src-pages-hire-qa-js" */),
  "component---src-pages-hire-react-js-js": () => import("./../../../src/pages/hire/react-js.js" /* webpackChunkName: "component---src-pages-hire-react-js-js" */),
  "component---src-pages-hire-react-native-js": () => import("./../../../src/pages/hire/react-native.js" /* webpackChunkName: "component---src-pages-hire-react-native-js" */),
  "component---src-pages-hire-shopify-js": () => import("./../../../src/pages/hire/shopify.js" /* webpackChunkName: "component---src-pages-hire-shopify-js" */),
  "component---src-pages-hire-ui-ux-designers-js": () => import("./../../../src/pages/hire/ui-ux-designers.js" /* webpackChunkName: "component---src-pages-hire-ui-ux-designers-js" */),
  "component---src-pages-hire-vue-js-js": () => import("./../../../src/pages/hire/vue-js.js" /* webpackChunkName: "component---src-pages-hire-vue-js-js" */),
  "component---src-pages-hire-webflow-js": () => import("./../../../src/pages/hire/webflow.js" /* webpackChunkName: "component---src-pages-hire-webflow-js" */),
  "component---src-pages-hire-woo-commerce-js": () => import("./../../../src/pages/hire/woo-commerce.js" /* webpackChunkName: "component---src-pages-hire-woo-commerce-js" */),
  "component---src-pages-hire-wordpress-js": () => import("./../../../src/pages/hire/wordpress.js" /* webpackChunkName: "component---src-pages-hire-wordpress-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-people-js": () => import("./../../../src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-templates-blog-details-js": () => import("./../../../src/templates/blog-details.js" /* webpackChunkName: "component---src-templates-blog-details-js" */)
}

