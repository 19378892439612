import { initMain } from '/static/js/main'

export const onRouteUpdate = ({ location, prevLocation }) => {
    initMain();
    if(location.pathname.startsWith('/blog/')) {
        setTimeout(() => {
            initMain();
        }, 2000);
    }

};

export const onClientEntry = () => {
    window.dataLayer = window.dataLayer || [];
};